<template>
    <div style="width:100%;height:100%">
         <div class="wrapper" @click.stop>     
            <van-icon @click="delVideo"  class="stop" size="18" name="cross" color="#fff" />
          <video :src="videoUrl" 
          id="videos" preload=""
           autoplay controls 
            width="100%"
             height="100%"
            playsinline="true"
             webkit-playsinline="true"
             x-webkit-airplay="true"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="portraint"
              class="video">
          <source :src="videoUrl" type="video/mp4">
          </video>
          </div>
    </div>
</template>

<script>
import { workUrl } from "../util/global.js";
let that
export default {
    data(){
        return {
            videoUrl: "",
        }
    },
    mounted(){
        that=this
        that.videoUrl = workUrl + that.$route.query.workOrder;
         wx.ready(function () {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: that.$route.query.title, // 分享标题
        desc: that.$route.query.title, // 分享描述
        link: window.location.href, // 分享链接
        imgUrl: "https://oss.cniot.fun/factory-platform/test/image/platform/20220316141425508.jpg", // 分享图标
        success: function () {
          // 设置成功

        },
      });
      wx.updateTimelineShareData({
       title: that.$route.query.title, // 分享标题
        desc: that.$route.query.title, // 分享描述
        link: window.location.href, // 分享链接
        imgUrl: "https://oss.cniot.fun/factory-platform/test/image/platform/20220316141425508.jpg", // 分享图标
        success: function () {
          // 设置成功

        },
      });
     
    });
    },
    methods:{
          delVideo() {
       
      that.$router.go(-1);
    },
    },
    beforeDestroy(){
    //    let videos=document.getElementById("videos");
    //    videos.pause();
       
    }
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.stop {
  position: absolute;
  top: 5vw;
  right: 5vw;
  z-index: 9999;
}
</style>